<template>
  <MainLayout :side-title="'Rekrutmen pegawai'">
    <div>
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Rekrutmen Pegawai Baru</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
          <router-link
            :to="{ name: 'create-recruitment' }"
            class="btn btn-primary shadow-md mr-2"
            >Rekrutmen Baru</router-link
          >

          <button class="btn btn-default shadow-md mr-2" @click="getItems">
            <RefreshCcwIcon class="w-4 h-4" />
          </button>
        </div>
      </div>
      <!-- BEGIN: HTML Table Data -->
      <div class="intro-y box p-5 mt-5">
        <AlertSuccess v-if="alertSuccess" :message="messageAlert" />
        <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
          <form
            id="tabulator-html-filter-form"
            class="xl:flex sm:mr-auto"
            @submit.prevent="onFilter"
          >
            <div class="sm:flex items-center sm:mr-4">
              <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
                >Field</label
              >
              <select
                id="tabulator-html-filter-field"
                v-model="filter.field"
                class="form-select w-full sm:w-32 xxl:w-full mt-2 sm:mt-0 sm:w-auto"
              >
                <option value="nm">NAMA</option>
                <option value="KODE">NIK</option>
              </select>
            </div>
            <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
              <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
                >Type</label
              >
              <select
                id="tabulator-html-filter-type"
                v-model="filter.type"
                class="form-select w-full mt-2 sm:mt-0 sm:w-auto"
              >
                <option value="like" selected>like</option>
                <option value="=">=</option>
                <option value="<">&lt;</option>
                <option value="<=">&lt;=</option>
                <option value=">">></option>
                <option value=">=">>=</option>
                <option value="!=">!=</option>
              </select>
            </div>
            <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
              <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
                >Value</label
              >
              <input
                id="tabulator-html-filter-value"
                v-model="filter.value"
                type="text"
                class="form-control sm:w-40 xxl:w-full mt-2 sm:mt-0"
                placeholder="Search..."
              />
            </div>
            <div class="mt-2 xl:mt-0">
              <button
                id="tabulator-html-filter-go"
                type="button"
                class="btn btn-primary w-full sm:w-16"
                @click="onFilter"
              >
                Go
              </button>
              <button
                id="tabulator-html-filter-reset"
                type="button"
                class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
                @click="onResetFilter"
              >
                Reset
              </button>
            </div>
          </form>
          <div class="flex mt-5 sm:mt-0">
            <button
              id="tabulator-print"
              class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2"
              @click="onPrint"
            >
              <PrinterIcon class="w-4 h-4 mr-2" /> Print
            </button>
            <div class="dropdown w-1/2 sm:w-auto">
              <button
                class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto"
                aria-expanded="false"
              >
                <FileTextIcon class="w-4 h-4 mr-2" /> Export
                <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
              </button>
              <div class="dropdown-menu w-40">
                <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                  <a
                    id="tabulator-export-csv"
                    href="javascript:"
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    @click="onExportCsv"
                  >
                    <FileTextIcon class="w-4 h-4 mr-2" /> Export CSV
                  </a>
                  <a
                    id="tabulator-export-json"
                    href="javascript:"
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    @click="onExportJson"
                  >
                    <FileTextIcon class="w-4 h-4 mr-2" /> Export JSON
                  </a>
                  <a
                    id="tabulator-export-xlsx"
                    href="javascript:"
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    @click="onExportXlsx"
                  >
                    <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                  </a>
                  <a
                    id="tabulator-export-html"
                    href="javascript:"
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    @click="onExportHtml"
                  >
                    <FileTextIcon class="w-4 h-4 mr-2" /> Export HTML
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="overflow-x-auto scrollbar-hidden">
          <div
            id="tabulator"
            ref="tableRef"
            class="mt-5 table-report table-report--tabulator"
          ></div>
        </div>
      </div>
      <!-- END: HTML Table Data -->
    </div>

    <!-- BEGIN: Modal Content -->
    <div id="delete-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
              <div class="text-3xl mt-5">Are you sure?</div>
              <div class="text-gray-600 mt-2">
                Do you really want to delete these records? <br />
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-danger w-24"
                @click="deleteRow"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Modal Content -->
    <template #rightside>
      <div class="grid grid-cols-12 gap-2">
        <div
          class="col-span-4 md:col-span-12 bg-white dark:bg-gray-800 rounded-sm p-3"
        >
          <h1 class="text-lg font-bold">{{ dataInfo.total }}</h1>
          <div class="font-semibold">Jumlah Rekrutmen</div>
        </div>
        <div
          class="col-span-4 md:col-span-12 bg-white dark:bg-gray-800 rounded-sm p-3"
        >
          <h1 class="text-lg font-bold">{{ dataInfo.active }}</h1>
          <div class="font-semibold">Jumlah Rekrutmen Diterima</div>
        </div>
        <div
          class="col-span-4 md:col-span-12 bg-white dark:bg-gray-800 rounded-sm p-3"
        >
          <h1 class="text-lg font-bold">{{ dataInfo.nonActive }}</h1>
          <div class="font-semibold">Jumlah Rekrutmen Ditolak</div>
        </div>
      </div>
    </template>
  </MainLayout>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from "vue";
import xlsx from "xlsx";
import feather from "feather-icons";
import { useStore } from "vuex";
import router from "@/router";
import { tblTabulator } from "@/utils/tabulator";
import { sdm } from "@/services/models";
import MainLayout from "@/layouts/main/MainLayout";
import { helper } from "@/utils/helper";
import AlertSuccess from "@/components/alerts/AlertSuccess.vue";
import cash from "cash-dom/dist/cash";

export default defineComponent({
  components: {
    MainLayout,
    AlertSuccess,
  },
  setup() {
    let deletingRow = ref();
    const idData = ref();
    const store = useStore();
    const tableRef = ref();
    const tabulator = ref();
    const filter = reactive({
      field: "nm",
      type: "like",
      value: "",
    });
    const accessToken = store.getters["auth/accessToken"];
    const editRow = (e, cell) => {
      idData.value = cell.getData().id_rekrutmen;
      router.push({ name: "edit-recruitment", params: { id: idData.value } });
    };
    const deleteRow = (e, cell) => {
      //e - the click event object
      cash("#delete-modal").modal("show");
      deletingRow.value = cell.getRow();
      idData.value = cell.getData().id_rekrutmen;
    };
    /* const dataSDM = (e, cell) => {
      idData.value = cell.getData().id_sdm;
      router.push({ name: "edit-sdm", params: { id: idData.value } });
    };*/
    const initTabulator = () => {
      let columns = [
        {
          formatter: "responsiveCollapse",
          width: 40,
          minWidth: 30,
          hozAlign: "center",
          resizable: false,
          headerSort: false,
        },
        {
          title: "NAMA",
          responsive: true,
          field: "nm",
          headerHozAlign: "left",
          vertAlign: "middle",
          print: false,
          download: false,
        },
        {
          title: "NIK",
          width: 100,
          field: "KODE",
          vertAlign: "middle",
          print: false,
          download: false,
        },
        {
          title: "Jenis Kelamin",
          width: 150,
          field: "jk",
          headerHozAlign: "left",
          vertAlign: "middle",
          print: false,
          download: false,
          formatter(cell) {
            if (cell.getData().jk == "L") {
              return "Laki-laki";
            } else {
              return "Perempuan";
            }
          },
        },
        {
          title: "Mulai Kerja/Kontrak/Pengangkatan",
          responsive: false,
          headerHozAlign: "left",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            let sdm_rekrutmen_ref = cell.getData().sdm_rekrutmen_ref;

            if (sdm_rekrutmen_ref?.tgl_pengangkatan) {
              let tgl1 = helper.tanggal(sdm_rekrutmen_ref?.tgl_pengangkatan);
              return `<div class="flex items-center lg:justify-center">
                          <div>
                              <div class="font-medium whitespace-nowrap">Tanggal Pengangkatan</div>
                              <div class="text-gray-600 text-xs whitespace-nowrap">${tgl1}</div>
                          </div>
                        </div>`;
            } else if (sdm_rekrutmen_ref?.tgl_mulai_kerja) {
              let tgl1 = helper.tanggal(sdm_rekrutmen_ref?.tgl_mulai_kerja);
              return `<div class="flex items-center lg:justify-center">
                          <div>
                              <div class="font-medium whitespace-nowrap">Tanggal Mulai Kerja</div>
                              <div class="text-gray-600 text-xs whitespace-nowrap">${tgl1}</div>
                          </div>
                        </div>`;
            } else if (sdm_rekrutmen_ref?.tgl_berakhir_kontrak_2) {
              let tgl1 = helper.tanggal(sdm_rekrutmen_ref?.tgl_mulai_kontrak_2);
              let tgl2 = helper.tanggal(
                sdm_rekrutmen_ref?.tgl_berakhir_kontrak_2
              );
              return `<div class="flex items-center lg:justify-center">
                          <div>
                              <div class="font-medium whitespace-nowrap">Kontrak 2</div>
                              <div class="text-gray-600 text-xs whitespace-nowrap">${tgl1} - ${tgl2}</div>
                          </div>
                        </div>`;
            } else if (sdm_rekrutmen_ref?.tgl_berakhir_kontrak_1) {
              let tgl1 = helper.tanggal(sdm_rekrutmen_ref?.tgl_mulai_kontrak_1);
              let tgl2 = helper.tanggal(
                sdm_rekrutmen_ref?.tgl_berakhir_kontrak_1
              );
              return `<div class="flex items-center lg:justify-center">
                          <div>
                              <div class="font-medium whitespace-nowrap">Kontrak 2</div>
                              <div class="text-gray-600 text-xs whitespace-nowrap">${tgl1} - ${tgl2}</div>
                          </div>
                        </div>`;
            } else if (sdm_rekrutmen_ref?.tgl_berakhir_training) {
              let tgl1 = helper.tanggal(sdm_rekrutmen_ref?.tgl_mulai_training);
              let tgl2 = helper.tanggal(
                sdm_rekrutmen_ref?.tgl_berakhir_training
              );
              return `<div class="flex items-center lg:justify-center">
                          <div>
                              <div class="font-medium whitespace-nowrap">Masa Percobaan</div>
                              <div class="text-gray-600 text-xs whitespace-nowrap">${tgl1} - ${tgl2}</div>
                          </div>
                        </div>`;
            } else {
              return "-";
            }
          },
        },
        {
          title: "Status",
          width: 180,
          headerHozAlign: "left",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            let aktif =
              cell.getData().status_aktif == "Y"
                ? "Aktif"
                : "<span class='text-red-500'>Tidak Aktif</span>";
            if (cell.getData().sdm_rekrutmen_ref?.status_rekrutmen == "1") {
              return `<div class="flex items-center lg:justify-center">
                            <i data-feather="check-square" class="text-green-500 w-6 h-6 mr-2"></i>
                            <div>
                                <div class="text-green-500 whitespace-nowrap">Diterima</div>
                                <div class="text-gray-600 text-xs whitespace-nowrap">${aktif}</div>
                            </div>
                        </div>`;
            } else {
              return `<div class="flex items-center lg:justify-center text-red-500">
                            <i data-feather="x-square" class="w-6 h-6 mr-2"></i> Ditolak
                        </div>`;
            }
          },
        },
        {
          title: "Jenis SDM",
          width: 150,
          field: "sdm_rekrutmen_ref.tenaga_pendidik",
          headerHozAlign: "left",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            if (cell.getData().sdm_rekrutmen_ref?.tenaga_pendidik == "1") {
              return `<div class="text-purple-700 mr-1">Dosen Tetap</div>`;
            } else if (
              cell.getData().sdm_rekrutmen_ref?.tenaga_pendidik == "2"
            ) {
              return `<div class="text-blue-900 mr-1">Dosen LB</div>`;
            } else if (
              cell.getData().sdm_rekrutmen_ref?.tenaga_pendidik == "3"
            ) {
              return `<div class="text-blue-500 mr-1">Tendik</div>`;
            } else {
              return "-";
            }
          },
        },
        // {
        //   title: "PTK",
        //   responsive: true,
        //   field: "nm",
        //   vertAlign: "middle",
        //   print: false,
        //   download: false,
        //   headerSort: false,
        //   formatter(cell) {
        //     if (cell.getData().id_sdm === null) {
        //       return `<div>
        //         <i data-feather="x-square" class="w-4 h-4 mr-1"></i>
        //         </div>
        //         <div class="text-gray-600 text-xs whitespace-nowrap">Tidak Terhubung</div>
        //       </div>`;
        //     } else {
        //       let reg_ptk = cell.getData().regptk;
        //       let lblptk = "";
        //       reg_ptk.forEach((ptk) => {
        //         lblptk +=
        //           '<div class="text-gray-500 text-xs whitespace-normal mt-2">' +
        //           ptk?.ikatan_kerja_sdm?.nm_ikatan_kerja +
        //           " " +
        //           ptk?.status_kepegawaian.nm_stat_pegawai;
        //         ("</div>");
        //         let prodi =
        //           ptk.id_sms !== null
        //             ? ptk?.sms?.nm_lemb +
        //               " (" +
        //               ptk?.sms?.jenjang_pendidikan?.nm_jenj_didik +
        //               ") "
        //             : "";
        //         lblptk +=
        //           '<div class="text-gray-600 text-md whitespace-normal">' +
        //           prodi +
        //           ptk?.satuan_pendidikan?.nm_lemb +
        //           " ";
        //         ("</div>");
        //       });
        //       return `<div>
        //         <div class="text-blue-600 text-xs whitespace-normal">PTK ${
        //           cell.getData()?.sdm?.status_keaktifan_pegawai?.nm_stat_aktif
        //         }</div>
        //         <div class="font-medium whitespace-nowrap">
        //           <span class="text-gray-600"> ${lblptk}</span>
        //         </div>
        //         </div>
        //       </div>`;
        //     }
        //   },
        // },
        {
          responsive: 1,
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          width: 85,
          formatter() {
            const a = cash(`<div class="flex lg:justify-center items-center">
                <a class="flex btn btn-sm btn-success w-18 items-center" href="javascript:;">
                  <i data-feather="archive" class="w-4 h-4 mr-1"></i> Open
                </a>
              </div>`);
            return a[0];
          },
          cellClick: editRow,
        },
        {
          responsive: 1,
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          width: 40,
          formatter() {
            const a = cash(`<div class="flex lg:justify-center items-center">
                <a class="flex items-center text-theme-6" href="javascript:;">
                  <i data-feather="trash-2" class="w-4 h-4 mr-1"></i>
                </a>
              </div>`);
            return a[0];
          },
          cellClick: deleteRow,
        },
      ];

      tabulator.value = tblTabulator.remoteDataTable(
        tableRef,
        sdm.Rekrutmen.endpoint,
        columns
      );
    };

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener("resize", () => {
        tabulator.value.redraw();
        feather.replace({
          "stroke-width": 1.5,
        });
      });
    };

    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value);
    };

    // On reset filter
    const onResetFilter = () => {
      filter.field = "nm";
      filter.type = "like";
      filter.value = "";
      onFilter();
    };

    // Export
    const onExportCsv = () => {
      tabulator.value.download("csv", "data.csv");
    };

    const onExportJson = () => {
      tabulator.value.download("json", "data.json");
    };

    const onExportXlsx = () => {
      const win = window;
      win.XLSX = xlsx;
      tabulator.value.download("xlsx", "data.xlsx", {
        sheetName: "Products",
      });
    };

    const onExportHtml = () => {
      tabulator.value.download("html", "data.html", {
        style: true,
      });
    };

    // Print
    const onPrint = () => {
      tabulator.value.print();
    };

    onMounted(() => {
      initTabulator();
      reInitOnResizeWindow();
    });

    return {
      store,
      tabulator,
      tableRef,
      filter,
      onFilter,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      accessToken,
      deletingRow,
      idData,
    };
  },
  data() {
    return {
      deleting: false,
      editing: false,
      dataTable: null,
      dataInfo: [],
      alertSuccess: this.$route.params.alertSuccess,
      messageAlert: this.$route.params.messageAlert,
    };
  },
  computed: {
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
    deletingStatus() {
      return this.deleting;
    },
  },
  watch: {},
  mounted() {
    this.getInfo();
  },
  methods: {
    getItems() {
      this.deleting = false;
      this.editing = false;
      this.tabulator.setData(
        sdm.Rekrutmen.endpoint,
        {},
        tblTabulator.ajaxConfig()
      );
    },
    deleteRow() {
      sdm.Rekrutmen.deleteData(this.idData).then((res) => {
        if (res.status === 200) {
          this.getItems();
          cash("#delete-modal").modal("hide");
        }
      });
    },
    getInfo() {
      // sdm.Dosen.getCustomPath("getInfoDosen").then((res) => {
      //   this.dataInfo = res.data.data;
      // });
      // this.dataInfo = {};
      // wspddikti
      //   .GetData({
      //     act: "GetRiwayatFungsionalDosen",
      //   })
      //   .then((res) => {
      //     console.log(res);
      //   });
    },
  },
});
</script>
